export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/Orders.vue'),
    },
    {
        path: '/edit-order/:id',
        name: 'editOrder',
        props: true,
        component: () => import('@/views/EditOrder.vue'),
    },
    {
        path: '/add-order',
        name: 'addOrder',
        component: () => import('@/views/EditOrder.vue'),
    },
    {
        path: '/customers',
        name: 'customers',
        props: true,
        component: () => import('@/views/Customers.vue'),
    },
    {
        path: '/edit-customer/:id',
        name: 'editCustomer',
        props: true,
        component: () => import('@/views/EditCustomer.vue'),
    },
    {
        path: '/add-customer',
        name: 'addCustomer',
        component: () => import('@/views/EditCustomer.vue'),
    },
    {
        path: '/products',
        name: 'products',
        component: () => import('@/views/Products.vue'),
    },
    {
        path: '/edit-product/:id',
        name: 'editProduct',
        props: true,
        component: () => import('@/views/EditProduct.vue'),
    },
    {
        path: '/add-product',
        name: 'addProduct',
        component: () => import('@/views/EditProduct.vue'),
    },
    {
        path: '/categories',
        name: 'categories',
        component: () => import('@/views/Categories.vue'),
    },
    {
        path: '/edit-category/:id',
        name: 'editCategory',
        props: true,
        component: () => import('@/views/EditCategory.vue'),
    },
    {
        path: '/add-category',
        name: 'addCategory',
        component: () => import('@/views/EditCategory.vue'),
    },
    {
        path: '/promotions',
        name: 'promotions',
        component: () => import('@/views/Promotions.vue'),
    },
    {
        path: '/add-promotion',
        name: 'addPromotion',
        component: () => import('@/views/EditPromotion.vue'),
    },
    {
        path: '/edit-promotion/:id',
        name: 'editPromotion',
        props: true,
        component: () => import('@/views/EditPromotion.vue'),
    },
    {
        path: '/contents',
        name: 'contents',
        component: () => import('@/views/Contents.vue'),
    },
    {
        path: '/edit-content/:id',
        name: 'editContent',
        props: true,
        component: () => import('@/views/EditContent.vue'),
    },
    {
        path: '/add-content',
        name: 'addContent',
        component: () => import('@/views/EditContent.vue'),
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('@/views/Messages.vue'),
    },
    {
        path: '/edit-message/:id',
        name: 'editMessage',
        props: true,
        component: () => import('@/views/EditMessage.vue'),
    },
    {
        path: '/add-message',
        name: 'addMessage',
        component: () => import('@/views/EditMessage.vue'),
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/Settings.vue'),
    },
    {
        path: '/edit-setting/:id',
        name: 'editSetting',
        props: true,
        component: () => import('@/views/EditSetting.vue'),
    },
    {
        path: '/add-setting',
        name: 'addSetting',
        component: () => import('@/views/EditSetting.vue'),
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/Users.vue'),
    },
    {
        path: '/edit-user/:id',
        name: 'editUser',
        props: true,
        component: () => import('@/views/EditUser.vue'),
    },
    {
        path: '/add-user',
        name: 'addUser',
        component: () => import('@/views/EditUser.vue'),
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/Profile.vue'),
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/views/Notifications.vue'),
    },
    {
        path: '/delivery',
        name: 'delivery',
        component: () => import('@/views/Delivery.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/login', 
        name: 'logout', 
    },
    {
        path: '/:pathMatch(.*)*', 
        name: 'notFound', 
        component: () => import('@/views/NotFound.vue')
    },
]
