<script setup>
import { inject } from 'vue'
import { RouterLink, useRouter } from 'vue-router'
import { useStorage } from '@/composables/storage'
import { useEchoStore } from '@/stores/echo'

const storage = useStorage()

const router = useRouter()
const user = inject('$user')

if(!user) {
    router.push({ name: 'login' })
}

function setAction(key) {
    if(key === 'logout') {
        storage.clear()
        window.location.href = '/login'
        // router.push({ name: 'login' })
    }
}

const echo = useEchoStore()

</script>

<template>
    <nav class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow">
        <div class="navbar-container d-flex content">
            <div class="bookmark-wrapper d-flex align-items-center">
                <ul class="nav navbar-nav d-xl-none">
                    <li class="nav-item">
                        <a class="nav-link menu-toggle" href="#">
                            <vue-feather type="menu"></vue-feather>
                        </a>
                    </li>
                </ul>
            </div>

            <ul class="nav navbar-nav align-items-center ms-auto">
                <li class="nav-item dropdown dropdown-cart me-25">
                    <RouterLink :to="{ name: 'orders' }" class="nav-link">
                        <vue-feather type="shopping-cart" size="21"></vue-feather>
                        
                        <span class="badge rounded-pill bg-primary badge-up cart-item-count">
                            {{ echo.newOrders }}
                        </span>
                    </RouterLink>
                </li>

                <li class="nav-item dropdown dropdown-notification me-25">
                    <RouterLink :to="{ name: 'messages' }" class="nav-link">
                        <vue-feather type="bell" size="21"></vue-feather>
    
                        <span class="badge rounded-pill bg-danger badge-up">
                            {{ echo.newMessages }}
                        </span>
                    </RouterLink>
                </li>

                <li v-if="user" class="nav-item dropdown dropdown-user">
                    <a class="nav-link dropdown-toggle dropdown-user-link" 
                        id="dropdown-user" 
                        href="#" 
                        data-bs-toggle="dropdown" 
                        aria-haspopup="true" aria-expanded="false">
    
                        <div class="user-nav d-sm-flex d-none">
                            <span class="user-name fw-bolder">
                                {{ user.email }}
                            </span>
                        </div>
                    </a>

                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-user">
                        <template v-for="item,key in $config.tree.account">
                            <button v-if="item && item.action" @click="setAction(item.action)" class="dropdown-item d-block w-100">
                                <vue-feather class="me-50" :type="item.icon" />
                                {{ $lang('menu.' + key) }}
                            </button>

                            <RouterLink v-else :to="{ name: key }" class="dropdown-item">
                                <vue-feather class="me-50" :type="item.icon" />
                                {{ $lang('menu.' + key) }}
                            </RouterLink>
                        </template>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>
