import { ref, inject } from 'vue'
import { defineStore } from 'pinia'
import { useEcho } from '@/composables/echo'
import { useApi } from '@/composables/api'

export const useEchoStore = defineStore('echo', () => {
    const { echoClient } = useEcho()

    const newOrders = ref(0)
    const newMessages = ref(0)
    const event = ref(null)

    if(echoClient) {
        const config = inject('$config');

        echoClient.private('admin').listen('.OrderUpdate', (e) => {
            newOrders.value = e.count.new
            event.value = 'OrderUpdate'
        })
    
        echoClient.private('admin').listen('.MessageUpdate', (e) => {
            newMessages.value = e.count.new
            event.value = 'MessageUpdate'
        })

        useApi(true, 'index', 'orders', { where: { value: 'status(=)new;created_at(>)'+config.countOrdersFrom } }).then(response => {
            if(response.ok.value) {
                newOrders.value = response.result.value.meta.total
            }
        })
    
        useApi(true, 'index', 'messages', { where: { value: 'response(null);created_at(>)'+config.countMessagesFrom } }).then(response => {
            if(response.ok.value) {
                newMessages.value = response.result.value.meta.total
            }
        })    
    }
    
    return { newOrders, newMessages, event }
})
