import config from '@/config/config'
import { useStorage } from '@/composables/storage'

export default {
    install: (app) => {     
        const storage = useStorage()
        const settings = storage.getJson('settings')
   
        if(settings) {
            app.provide('$settings', settings)
            app.config.globalProperties.$settings = settings
        }

        function getItems(items)
        {
            let result = {}

            for (let i = 0; i < items.length; i++) {
                result[items[i].key] = getOptions(items[i]) 
            }

            return result
        }
        
        function getOptions(item) {
            let result = {}

            if(item.options) {
                for (let j = 0; j < item.options.length; j++) {
                    result[item.options[j].key] = item.options[j]

                    if(item.options[j]['lang']) {
                        result[item.options[j].key]['title'] = item.options[j]['lang'][config.lang.default]
                    }
                }
            }

            return result
        }

        const setting = (key = '') => {
            let result = null

            if(settings !== null) {
                const params = key.split('.')

                if(params[2]) {
                    if(settings[params[0]] && settings[params[0]][params[1]] && settings[params[0]][params[1]][params[2]]) {
                        result = settings[params[0]][params[1]][params[2]]
                    }
                }
                else if(params[1]) {
                    if(settings[params[0]] && settings[params[0]][params[1]]) {
                        result = settings[params[0]][params[1]]
                    }
                }
                else if(settings[params[0]]) {
                    result = settings[params[0]]
                }
                else {
                    result = settings
                }
            }

            if(result != null) {
                return result
            }
        }

        app.config.globalProperties.$setting = setting

        app.provide('$setting', setting)
    }
}
